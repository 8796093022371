/* eslint-disable react/require-default-props */
import React from 'react'

const AustraliaFlag = ({
  width,
  height,
}: {
  width?: string
  height?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '60.703'}
      height={height ?? '60.703'}
      version="1.1"
      viewBox="0 0 473.677 473.677"
      xmlSpace="preserve"
    >
      <path
        fill="#252F6B"
        d="M236.835 0v473.677c130.807 0 236.842-106.036 236.842-236.835C473.677 106.028 367.641 0 236.835 0z"
      />
      <path
        fill="#273375"
        d="M236.835 0C106.036 0 0 106.032 0 236.838c0 130.799 106.036 236.835 236.835 236.835 130.806 0 130.806-473.673 0-473.673z"
      />
      <g fill="#F3F4F5">
        <path d="M162.425 363.057L178.396 343.147 153.723 347.616 145.163 323.716 135.859 347.616 110.669 343.147 126.678 362.619 110.056 382.069 135.391 378.09 143.936 401.496 153.244 378.101 179.084 382.719z" />
        <path d="M351.206 380.644L360.263 369.361 346.277 371.893 341.427 358.352 336.154 371.893 321.88 369.361 330.952 380.389 321.536 391.417 335.889 389.159 340.731 402.416 346.004 389.166 360.648 391.784z" />
        <path d="M351.206 131.338L360.263 120.052 346.277 122.587 341.427 109.046 336.154 122.587 321.88 120.052 330.952 131.087 321.536 142.108 335.889 139.853 340.731 153.117 346.004 139.86 360.648 142.474z" />
        <path d="M409.797 195.83L418.847 184.544 404.869 187.08 400.018 173.539 394.746 187.08 380.464 184.544 389.536 195.58 380.12 206.604 394.473 204.345 399.315 217.61 404.588 204.353 419.232 206.967z" />
        <path d="M300.523 224.502L309.581 213.219 295.595 215.755 290.744 202.214 285.472 215.755 271.201 213.219 280.27 224.247 270.857 235.275 285.21 233.02 290.053 246.273 295.325 233.028 309.97 235.642z" />
      </g>
      <g fill="#29337A">
        <path d="M49.74 91.713a241.88 241.88 0 00-10.62 14.775h25.388L49.74 91.713z" />
        <path d="M172.043 236.838L194.858 236.838 172.043 214.023z" />
        <path d="M96.417 46.135c-5.434 4.009-10.665 8.261-15.732 12.707l15.732 15.725V46.135z" />
        <path d="M73.595 236.838L96.417 236.838 96.417 214.016z" />
        <path d="M236.835 0c-22.475 0-44.187 3.197-64.792 9.05v65.517l64.799-64.796V0h-.007z" />
        <path d="M236.842 215.003L236.842 182.121 203.96 182.121z" />
        <path d="M9.772 236.838L64.5 182.121H6.428C2.266 199.693 0 217.998 0 236.838h9.772z" />
        <path d="M203.938 106.488L236.842 106.488 236.842 73.591z" />
      </g>
      <g fill="#FFF">
        <path d="M236.842 221.91L236.842 215.003 203.96 182.121 236.842 182.121 236.842 170.345 185.277 170.345z" />
        <path d="M64.508 106.488H39.12a238.13 238.13 0 00-7.259 11.765h49.875L52.133 88.658a213.744 213.744 0 00-2.397 3.051l14.772 14.779z" />
        <path d="M96.417 214.016L96.417 236.838 108.186 236.838 108.186 196.788 68.139 236.838 73.595 236.838z" />
        <path d="M172.043 9.046a238.87 238.87 0 00-11.765 3.672v79.077l76.564-76.56V9.779l-64.799 64.784V9.046zM96.002 182.121l.415-.415 11.357-11.357H9.532a233.524 233.524 0 00-3.104 11.772H64.5L9.772 236.838h31.51l54.72-54.717zM9.772 236.838L64.5 182.121 9.772 236.838z" />
        <path d="M236.842 118.257L236.842 106.488 203.938 106.488 236.842 73.591 236.842 42.089 160.671 118.257z" />
        <path d="M236.842 73.591l-32.905 32.897 32.905-32.897zM96.417 74.571L80.685 58.846a237.146 237.146 0 00-16.032 15.471l31.764 31.764.337.344 11.432 11.432V38.028c-4.001 2.599-7.939 5.28-11.765 8.107v28.436h-.004z" />
        <path d="M172.043 214.023L194.858 236.838 224.917 236.838 160.274 172.2 160.274 236.838 172.043 236.838z" />
      </g>
      <g fill="#D32030">
        <path d="M96.417 106.081l.337.344-.337-.344zM197.042 182.121l-11.765-11.772h51.565v-52.092h-76.171l-.393.4v-.4h.393l76.171-76.164V15.235l-76.568 76.564V12.722a235.984 235.984 0 00-52.092 25.309v79.825l.408.4h-.408v-.4L96.75 106.425l.067.067h-.4v-.408L64.653 74.32a239.4 239.4 0 00-12.52 14.345l29.602 29.595H31.861a235.063 235.063 0 00-22.329 52.092h98.242l.408-.415v.415h-.408L96.417 181.71v.415h-.415l-54.721 54.714h26.858l40.047-40.051v40.051h52.092V172.2l64.639 64.639h8.889c1.013-1.058 2.046-2.109 3.04-3.197V221.91l-39.804-39.789z" />
        <path d="M160.274 91.799L236.842 15.235 236.842 15.235z" />
        <path d="M96.417 182.121L96.417 181.702 96.002 182.121z" />
        <path d="M108.186 169.93L107.775 170.345 108.186 170.345z" />
        <path d="M160.274 118.657L160.671 118.257 160.274 118.257z" />
        <path d="M96.417 106.488L96.821 106.488 96.754 106.425 96.417 106.081z" />
        <path d="M108.593 118.257L108.186 117.853 108.186 118.257z" />
      </g>
    </svg>
  )
}

export default AustraliaFlag
